export const siteConfig = {
    name: "Gabrielle Rancifer",
    url: "gabriellerancifer@netlify.app",
    ogImage: "/profile_pic.jpg",
    description:
      "Welcome to my Law Student Portfolio. Explore my journey through legal studies, showcasing projects, research, and achievements. Discover my passion for justice and commitment to excellence in the realm of law.",
    links: {
      linkedin: "https://www.linkedin.com/in/gabrielle-rancifer/",
    },
  }
  
  export type SiteConfig = typeof siteConfig