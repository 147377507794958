import { Blog } from "@/models/blog";

export const myBlog: Blog = {
  id: 1,
  name: "The Gavel Gab",
  description:
    "Candid insights and reflections from a law student's journey, demystifying legal labyrinths one case at a time.",
  authors: [
    {
      id: "07d212c6-d872-49dd-b980-fbb48109532f",
      name: "Gabrielle Rancifer", 
      profileImage: "", 
      bio: "", 
      socialLinks: {
        twitter: undefined, 
        linkedin: undefined, 
        github: undefined, 
      },
    },
  ],
  articles: [
    {
      id: 2, 
      title: "Health Literacy — the public health crisis you never knew about.", 
      category: "Health Literacy",
      content:
        "Do you enjoy going to doctor’s appointments? What about sifting through after-visit summaries? Do you ever find yourself calling a friend or family member that is a doctor and asking them to explain what was discussed during your office visit? Chances are, you might not be alone. ", 
      publishedAt: new Date("2022-09-07"), 
      coverImage: "/article-images/Health_LIteracy.jpg", 
      link: "https://grancifer.medium.com/health-literacy-the-public-health-crisis-you-never-knew-about-5c26eaa09494", 
      tags: [], 
      claps: 0, 
      featured: true,
      author: "Gabrielle Rancifer", 
    },
    {
      id: 3, 
      title:
        "UCSF researcher cautions against stroke screening for asymptomatic patients, due to potential harms.", 
      category: "Stroke",
      content: "", 
      publishedAt: new Date("2022-09-07"), 
      coverImage: "/article-images/UCSF_researcher.jpg", 
      link: "https://grancifer.medium.com/ucsf-researcher-cautions-against-stroke-screening-for-asymptomatic-patients-due-to-potential-harms-2d83bd1b5c7d", 
      tags: [], 
      claps: 0, 
      featured: false,
      author: "Gabrielle Rancifer", 
    },
    {
      id: 4, 
      title: "Will swallowing a seed result in a plant growing in my stomach?", 
      category: "Science",
      content: "", 
      publishedAt: new Date("2022-03-09"), 
      coverImage: "/article-images/Swallowing_seed.jpg", 
      link: "https://grancifer.medium.com/will-swallowing-a-seed-result-in-a-plant-growing-in-my-stomach-74b4c65c6746", 
      tags: [], 
      claps: 0, 
      featured: false,
      author: "Gabrielle Rancifer", 
    },
    {
      id: 5, 
      title: "Feature Story: The Incredible Mrs. Alger", 
      category: "Inspiring",
      content: "/article-images/", 
      publishedAt: new Date("2022-09-07"), 
      coverImage: "/article-images/article_placeholder.jpg", 
      link: "https://grancifer.medium.com/feature-story-the-incredible-mrs-alger-f942af56256", 
      tags: [], 
      claps: 0, 
      featured: false,
      author: "Gabrielle Rancifer", 
    },
    {
      id: 6, 
      title:
        "Recreated Moment: A Day in the Life of a Teen with Post-Concussion Syndrome", 
      category: "Concussions",
      content: "", 
      publishedAt: new Date("2022-09-07"), 
      coverImage: "/article-images/article_placeholder.jpg", 
      link: "https://grancifer.medium.com/recreated-moment-a-day-in-the-life-of-a-teen-with-post-concussion-syndrome-9db635ef2257", 
      tags: [], 
      claps: 0, 
      featured: false,
      author: "Gabrielle Rancifer", 
    },
    {
      id: 7, 
      title: "Snapshot: 9pm Skate Session with an Evanston Teen", 
      category: "Journalism",
      content: "", 
      publishedAt: new Date("2022-09-07"), 
      coverImage: "/article-images/article_placeholder.jpg", 
      link: "https://grancifer.medium.com/snapshot-9pm-skate-session-with-an-evanston-teen-e684f4c4c0e", 
      tags: [], 
      claps: 0, 
      featured: false,
      author: "Gabrielle Rancifer", 
    },
    {
      id: 8, 
      title: "Unheeded Warnings:", 
      category: "Restore Democracy",
      content: "", 
      publishedAt: new Date("2020-08-19"), 
      coverImage: "/article-images/Unheeded_Warnings.jpg", 
      link: "https://grancifer.medium.com/unheeded-warnings-caa1fba0cebc", 
      tags: [], 
      claps: 0, 
      featured: false,
      author: "Gabrielle Rancifer", 
    },
    {
      id: 9, 
      title: "The Evolution of Advertisements", 
      category: "Social Media",
      content: "", 
      publishedAt: new Date("2020-08-19"), 
      coverImage: "/article-images/The Evolution_of_Advertisements.jpg", 
      link: "https://grancifer.medium.com/the-evolution-of-advertisements-90059a2a0175", 
      tags: [], 
      claps: 0, 
      featured: false,
      author: "Gabrielle Rancifer", 
    },
    {
      id: 10, 
      title: "PageRank… I think not!", 
      category: "Google",
      content: "", 
      publishedAt: new Date("2020-08-19"), 
      coverImage: "/article-images/PageRank.jpg", 
      link: "https://grancifer.medium.com/pagerank-i-think-not-5025e058172b", 
      tags: [], 
      claps: 0, 
      featured: false,
      author: "Gabrielle Rancifer", 
    },
    {
      id: 11, 
      title: "Online Privacy and its Paradoxes:", 
      category: "Online Privacy",
      content: "", 
      publishedAt: new Date("2020-08-19"), 
      coverImage: "/article-images/Online_Privacy_and_its_Paradoxes.jpg", 
      link: "https://grancifer.medium.com/online-privacy-and-its-paradoxes-c457ceaaf4e0", 
      tags: [], 
      claps: 0, 
      featured: false,
      author: "Gabrielle Rancifer", 
    },
    {
      id: 12, 
      title: "Filters vs. Democracy", 
      category: "Social Media",
      content: "", 
      publishedAt: new Date("2020-08-19"), 
      coverImage: "/article-images/Filter_vs_Democracy.jpg", 
      link: "https://grancifer.medium.com/filters-vs-democracy-eab05a584e1", 
      tags: [], 
      claps: 0, 
      featured: false,
      author: "Gabrielle Rancifer", 
    },
    {
      id: 13, 
      title:
        "College-aged students of color said they believe race will affect their future job opportunities in the business world.", 
      category: "Gabrielle Rancifer",
      content: "", 
      publishedAt: new Date("2020-03-31"), 
      coverImage: "/article-images/Colleged_Aged_Students.jpg", 
      link: "https://grancifer.medium.com/college-aged-students-of-color-said-they-believe-race-will-affect-their-future-job-opportunities-178a670884a8", 
      tags: [], 
      claps: 0, 
      featured: false,
      author: "Gabrielle Rancifer", 
    },
    {
      id: 14, 
      title: "The Monster in the Shadow of America’s Education System", 
      category: "Education",
      content: "", 
      publishedAt: new Date("2019-12-11"), 
      coverImage: "/article-images/The_Monster_in_the_Shadow.jpg", 
      link: "https://grancifer.medium.com/the-monster-in-the-shadow-of-americas-education-system-234bf717b9e9", 
      tags: [], 
      claps: 0, 
      featured: false,
      author: "Gabrielle Rancifer", 
    },
    {
      id: 15, 
      title: "Antiseptic Evolution Diminishes Operation Death Toll:", 
      category: "Medicine",
      content: "", 
      publishedAt: new Date("2019-07-28"), 
      coverImage: "/article-images/Antiseptics_Evo.jpg", 
      link: "https://grancifer.medium.com/antiseptic-evolution-diminishes-operation-death-toll-7ac932427c91", 
      tags: [], 
      claps: 0, 
      featured: false,
      author: "Gabrielle Rancifer", 
    },
    {
      id: 16, 
      title: "Separate the Fed!", 
      category: "Federal Reserve",
      content: "", 
      publishedAt: new Date("2019-07-28"), 
      coverImage: "/article-images/Seperate_the_Fed.jpg", 
      link: "https://grancifer.medium.com/separate-the-fed-7cb52fa57ebc", 
      tags: [], 
      claps: 0, 
      featured: false,
      author: "Gabrielle Rancifer", 
    },
    {
      id: 17, 
      title: "A Curious Catalyst", 
      category: "History",
      content: "", 
      publishedAt: new Date("2019-07-28"), 
      coverImage: "/article-images/A_curious_Catalyst.jpg", 
      link: "https://grancifer.medium.com/a-curious-catalyst-3b94f27d874d", 
      tags: [], 
      claps: 0, 
      featured: false,
      author: "Gabrielle Rancifer", 
    },
    {
      id: 18, 
      title: "Electra and…", 
      category: "Electra",
      content: "", 
      publishedAt: new Date("2019-07-28"), 
      coverImage: "/article-images/Electra_and.jpg", 
      link: "https://grancifer.medium.com/electra-vs-greek-society-e21b7aec0d6b", 
      tags: [], 
      claps: 0, 
      featured: false,
      author: "Gabrielle Rancifer", 
    },
    {
      id: 19, 
      title: "Analyzing Dracula:", 
      category: "Gothic Literature",
      content: "", 
      publishedAt: new Date("2019-07-28"), 
      coverImage: "/article-images/Analyzing_Dracula.jpg", 
      link: "https://grancifer.medium.com/analyzing-dracula-968f14e02649", 
      tags: [], 
      claps: 0, 
      featured: false,
      author: "Gabrielle Rancifer", 
    },
    {
      id: 20, 
      title: "LEMS not Limbs:", 
      category: "Health",
      content: "", 
      publishedAt: new Date("2019-07-28"), 
      coverImage: "/article-images/Lems_not_Limbs.jpg", 
      link: "https://grancifer.medium.com/lems-not-limbs-759a11d6fc7c", 
      tags: [], 
      claps: 0, 
      featured: false,
      author: "Gabrielle Rancifer", 
    },
    {
      id: 21, 
      title: "The Descent of Man:", 
      category: "Decent of Man",
      content: "", 
      publishedAt: new Date("2019-07-28"), 
      coverImage: "/article-images/The_Descent_of_Man.jpg", 
      link: "https://grancifer.medium.com/the-descent-of-man-594d509429c3", 
      tags: [], 
      claps: 0, 
      featured: false,
      author: "Gabrielle Rancifer", 
    },
    {
      id: 22, 
      title: "Brushing over the Truth", 
      category: "Balkan Ghosts",
      content: "", 
      publishedAt: new Date("2019-07-28"), 
      coverImage: "/article-images/Brushing_over_the_Truth.jpg", 
      link: "https://grancifer.medium.com/brushing-over-the-truth-db109b2deb87", 
      tags: [], 
      claps: 0, 
      featured: false,
      author: "Gabrielle Rancifer", 
    },
    {
      id: 23, 
      title: "The Lost One", 
      category: "Love",
      content: "", 
      publishedAt: new Date("2019-03-19"), 
      coverImage: "/article-images/article_placeholder.jpg", 
      link: "https://grancifer.medium.com/the-lost-one-dba235868215", 
      tags: [], 
      claps: 0, 
      featured: false,
      author: "Gabrielle Rancifer", 
    },
    {
      id: 24, 
      title: "Medicine & Technology:", 
      category: "Health",
      content: "", 
      publishedAt: new Date("2018-01-19"), 
      coverImage: "/article-images/article_placeholder.jpg", 
      link: "https://grancifer.medium.com/medicine-technology-4360cb30c7d7", 
      tags: [], 
      claps: 0, 
      featured: false,
      author: "Gabrielle Rancifer", 
    },
    {
      id: 25, 
      title: "Where I’m From Poem", 
      category: "Where Im From",
      content: "", 
      publishedAt: new Date("2018-01-15"), 
      coverImage: "/article-images/Where_I'm_From_Poem.jpg", 
      link: "https://grancifer.medium.com/where-im-from-poem-287f99f96945", 
      tags: [], 
      claps: 0, 
      featured: false,
      author: "Gabrielle Rancifer", 
    },
    {
      id: 26, 
      title: "A Veces Yo Lloro", 
      category: "Poetry",
      content: "", 
      publishedAt: new Date("2017-12-20"), 
      coverImage: "/article-images/article_placeholder.jpg", 
      link: "https://grancifer.medium.com/a-veces-yo-lloro-7f7041759c4e", 
      tags: [], 
      claps: 0, 
      featured: false,
      author: "Gabrielle Rancifer", 
    },
    {
      id: 27, 
      title: "Responsibilities of Today for Tomorrow", 
      category: "Climate Change",
      content: "", 
      publishedAt: new Date("2017-12-01"), 
      coverImage: "/article-images/Responsibilities_of_Today_for_Tomorrow.jpg", 
      link: "https://grancifer.medium.com/responsibilities-of-today-for-tomorrow-17689d86be3b", 
      tags: [], 
      claps: 0, 
      featured: false,
      author: "Gabrielle Rancifer", 
    },
    {
      id: 28, 
      title: "colour me blind…", 
      category: "Poetry",
      content: "", 
      publishedAt: new Date("2017-11-30"), 
      coverImage: "/article-images/colour_me_blind.jpg", 
      link: "https://grancifer.medium.com/colour-me-blind-974ad435f472", 
      tags: [], 
      claps: 0, 
      featured: false,
      author: "Gabrielle Rancifer", 
    },
    {
      id: 29, 
      title:
        "Visiting Scholar Writes Piece on the Deep Ocean and Seabed Mining", 
      category: "Marine Ecosystems",
      content: "", 
      publishedAt: new Date("2017-11-30"), 
      coverImage: "/article-images/gabi_ocean_and_seabed_mining_image.png", 
      link: "https://gcrancifer.my.canva.site/do-we-protect-it", 
      tags: [], 
      claps: 0, 
      featured: false,
      author: "Gabrielle Rancifer", 
    },
    {
      id: 30, 
      title: "Alaskan memories inspire climate change work", 
      category: "Environmental Studies",
      content:
        "In native Alaskan towns, climate change erodes the coast where many houses are located. As the air warms due to carbon dioxide trapped in the atmosphere from human fossil fuel  emissions, the frozen layer of Earth called permafrost begins to melt. The houses are on the permafrost because it has never melted in all the generations people have lived here and caused no threat. ", 
      publishedAt: new Date('2022-12-17'), 
      coverImage: "/article-images/Ozone-Hole-figure.png", 
      link: "https://news.medill.northwestern.edu/chicago/alaskan-memories-inspire-climate-change-work/", 
      tags: [], 
      claps: 0, 
      featured: true,
      author: "Grace Finnell-Gudwien and Gabrielle Rancifer", 
    },
    {
      id: 31, 
      title: "Will evaporation dry up water sources as climate change worsens?", 
      category: "Environmental Studies",
      content:
        "Researchers closely follow and evaluate both evaporation and precipitation because they are key components of the water cycle. Evaporation delivers water to the atmosphere, and precipitation delivers water to the many systems and bodies that depend on it. The two work to maintain the flows and stands of rivers, lakes and other bodies of water.", 
      publishedAt: new Date('2022-12-18'), 
      coverImage: "/article-images/Will_evaporation_dry_up_water.jpg", 
      link: "https://news.medill.northwestern.edu/chicago/will-evaporation-dry-up-water-sources-as-climate-change-worsens/", 
      tags: [], 
      claps: 0, 
      featured: true,
      author: "Gabrielle Rancifer", 
    },
    {
      id: 32, 
      title: "Meaningful Movements", 
      category: "Physical Health",
      content:
        "When a person experiences trauma — gun violence, abuse, or military combat, for example — the body holds onto those memories in tissues, muscles, and physiology. Even as the person mentally tries to work past the experience, physical reactions that helped them through the traumatic experience may persist.", 
      publishedAt: new Date('2023-03-15'), 
      coverImage: "/article-images/Lifestyle-Fitness-Trauma-Informed-yoga.png", 
      link: "https://chicagohealthonline.com/meaningful-movements/", 
      tags: [], 
      claps: 0, 
      featured: true,
      author: "Gabrielle Rancifer", 
    },
    {
      id: 33, 
      title: "U of A School of Law Student Selected for Shook Scholars Institute", 
      category: "Achievements",
      content:
        "First-year law student Gabrielle Rancifer was selected to participate in the Shook Scholars Institute held April 3-5, in Kansas City, Missouri. Created by Shook Hardy & Bacon, LLP, the Institute is an innovative diversity...", 
      publishedAt: new Date('2024-05-16'), 
      coverImage: "/article-images/Gabrielle_Rancifer_Shook_Scholar.jpg", 
      link: "https://news.uark.edu/articles/70333/u-of-a-school-of-law-student-selected-for-shook-scholars-institute?utm_source=arkansas-news&utm_medium=email_2024-05-01&utm_campaign=news_headlines&utm_content=u-of-a-school-of-law-student-selected-for-shook-scholars-institute/", 
      tags: [], 
      claps: 0, 
      featured: true,
      author: "Erin Feller, Director of Development and External Relations", 
    },
    {
      id: 34, 
      title: "Arkansas Achievers", 
      category: "Achievements",
      content:
        "Gabrielle Rancifer, a first year student at the University of Arkansas School of Law in Fayetteville, was among 20 students selected nationally to participate in the Shook Scholars Institute held April 3-5, in Kansas City, Mo.", 
      publishedAt: new Date('2024-05-05'), 
      coverImage: "/article-images/Arkansas_University.png", 
      link: "https://edition.arkansasonline.com/article/281960317833742", 
      tags: [], 
      claps: 0, 
      featured: true,
      author: "Gabrielle Rancifer", 
    },
  ],
};
