import { useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { Dialog } from "@headlessui/react";
import { XMarkIcon } from "@heroicons/react/24/outline";

import { cn } from "@/lib/utils";
import { nav } from "@/components/structure/navigation";

export const MainNav2 = () => {
  const location = useLocation();
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);

  const renderNavLinks = () => {
    return nav.map((link, i) => {
      if (link.isMenu && !link.isPrivate) {
        return (
          <li key={i}>
            <Link
              to={link.path}
              className={cn(
                "relative block px-3 py-2 transition hover:text-pink-500 dark:hover:text-pink-400",
                location.pathname.startsWith(link.path)
                  ? "text-gray-900 font-bold"
                  : "text-gray-600"
              )}
              onClick={() => setMobileMenuOpen(false)}
            >
              {link.name}
            </Link>
          </li>
        );
      }
      return null;
    });
  };

  return (
    <>
      {/* Desktop Navigation */}
      <nav className="pointer-events-auto hidden md:block">
        <ul className="flex rounded-full bg-white/90 px-3 text-sm font-medium text-zinc-800 shadow-lg shadow-zinc-800/5 ring-1 ring-zinc-900/5 backdrop-blur dark:bg-zinc-800/90 dark:text-zinc-200 dark:ring-white/10">
          {nav.map((link, i) => {
            if (link.isMenu && !link.isPrivate) {
              return (
                <li key={i}>
                  <Link
                    to={link.path}
                    className={cn(
                      "relative block px-3 py-2 transition hover:text-pink-500 dark:hover:text-pink-400 dark:text-zinc-200",
                      location.pathname.startsWith(link.path)
                        ? "text-gray-900 font-bold"
                        : "text-gray-600"
                    )}
                  >
                    {link.name}
                  </Link>
                </li>
              );
            }
            return null;
          })}
        </ul>
      </nav>

      {/* Toggle Button */}
      <div className="pointer-events-auto md:hidden">
        <button
          className="group flex items-center rounded-full bg-white/90 px-4 py-2 text-sm font-medium text-zinc-800 shadow-lg shadow-zinc-800/5 ring-1 ring-zinc-900/5 backdrop-blur dark:bg-zinc-800/90 dark:text-zinc-200 dark:ring-white/10 dark:hover:ring-white/20"
          type="button"
          aria-expanded={mobileMenuOpen}
          onClick={() => setMobileMenuOpen(!mobileMenuOpen)}
        >
          Menu
          <svg
            viewBox="0 0 8 6"
            aria-hidden="true"
            className="ml-3 h-auto w-2 stroke-zinc-500 group-hover:stroke-zinc-700 dark:group-hover:stroke-zinc-400"
          >
            <path
              d="M1.75 1.75 4 4.25l2.25-2.5"
              fill="none"
              strokeWidth="1.5"
              strokeLinecap="round"
              strokeLinejoin="round"
            ></path>
          </svg>
        </button>
      </div>

      {/* Mobile Navigation */}
      <Dialog
        as="div"
        className="pointer-events-auto md:hidden"
        open={mobileMenuOpen}
        onClose={() => setMobileMenuOpen(false)}
      >
        {/* Overlay */}
        <div className="fixed inset-0 z-50 bg-zinc-800/40 backdrop-blur-sm dark:bg-black/80"></div>

        {/* Popover Panel */}
        <Dialog.Panel className="fixed inset-x-4 top-8 z-50 origin-top rounded-3xl bg-white p-8 ring-1 ring-zinc-900/5 dark:bg-zinc-900 dark:ring-zinc-800">
          <div className="flex flex-row-reverse items-center justify-between">
            <button
              aria-label="Close menu"
              className="-m-1 p-1"
              type="button"
              onClick={() => setMobileMenuOpen(false)}
            >
              <XMarkIcon className="h-6 w-6 text-zinc-500 dark:text-zinc-400" aria-hidden="true" />
            </button>
            <h2 className="text-sm font-medium text-zinc-600 dark:text-zinc-400">
              Navigation
            </h2>
          </div>
          <nav className="mt-6">
            <ul className="-my-2 divide-y divide-zinc-100 text-base text-zinc-800 dark:divide-zinc-100/5 dark:text-zinc-300">
              {renderNavLinks()}
            </ul>
          </nav>
        </Dialog.Panel>
      </Dialog>
    </>
  );
};
