import React, { useState } from "react";
import { useBlog } from "@/data/blog-context"; // Import the useBlog hook
import AllArticles from "./all-blogs";

const BlogPage = () => {
  const [showAllArticles, setShowAllArticles] = useState(false);
  const { blog } = useBlog();

  const recentArticles = blog.articles
    .slice()
    .sort((a, b) => new Date(b.publishedAt).getTime() - new Date(a.publishedAt).getTime())
    .slice(0, 6); // Get the top 10 most recent articles

  const handleShowAllArticles = () => {
    setShowAllArticles(true);
  };

  const handleCloseAllArticles = () => {
    setShowAllArticles(false);
  };

  return (
    <>
      <div className="sm:px-8 mt-16 sm:mt-32">
        <div className="mx-auto w-full max-w-7xl lg:px-8">
          <div className="relative px-4 sm:px-8 lg:px-12">
            <div className="mx-auto max-w-2xl lg:max-w-5xl">
              <header className="max-w-2xl">
                <h1 className="text-4xl font-bold tracking-tight text-zinc-800 dark:text-zinc-100 sm:text-5xl">
                  From Medill to Magistrate: Chronicles of a Science-Loving Law Student
                </h1>
                <p className="mt-6 text-base text-zinc-600 dark:text-zinc-400">
                  From late-night studies to my dedicated research desk, I've penned insights into the intersections of Health, Environment, and Science. Through each blog article, journey with me as I explore the intricacies of these realms, always from a legal vantage point and driven by unwavering passion.
                </p>
              </header>
              <div className="mt-16 sm:mt-20">
                <section className="text-gray-600 body-font">
                  <div className="container px-5 py-24 mx-auto">
                    <div className="flex flex-wrap -m-4">
                      {recentArticles.map((article) => (
                        <div key={article.id} className="p-4 md:w-1/3">
                          <div className="h-full border-2 border-gray-200 border-opacity-60 rounded-lg overflow-hidden">
                            <img
                              className="lg:h-48 md:h-36 w-full object-cover object-center"
                              src={article.coverImage}
                              alt={article.title}
                            />
                            <div className="p-6">
                              <h2 className="tracking-widest text-xs title-font font-medium text-gray-400 mb-1">
                                {article.category}
                              </h2>
                              <h1 className="title-font text-lg font-medium text-gray-900 mb-3 dark:text-zinc-400">
                                {article.title}
                              </h1>
                              <p className="leading-relaxed mb-3 dark:text-zinc-400">
                                {article.content}
                              </p>
                              <div className="flex items-center flex-wrap">
                                <a
                                  href={article.link}
                                  className="text-pink-500 inline-flex items-center md:mb-2 lg:mb-0"
                                >
                                  Learn More
                                  <svg
                                    className="w-4 h-4 ml-2"
                                    viewBox="0 0 24 24"
                                    stroke="currentColor"
                                    strokeWidth="2"
                                    fill="none"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                  >
                                    <path d="M5 12h14"></path>
                                    <path d="M12 5l7 7-7 7"></path>
                                  </svg>
                                </a>
                                <span className="text-gray-400 mr-3 inline-flex items-center lg:ml-auto md:ml-0 ml-auto leading-none text-sm pr-3 py-1 border-r-2 border-gray-200">
                                  <svg
                                    className="w-4 h-4 mr-1"
                                    stroke="currentColor"
                                    strokeWidth="2"
                                    fill="none"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    viewBox="0 0 24 24"
                                  >
                                    <path d="M1 12s4-8 11-8 11 8 11 8-4 8-11 8-11-8-11-8z"></path>
                                    <circle cx="12" cy="12" r="3"></circle>
                                  </svg>
                                  {article.claps}
                                </span>
                                <span className="text-gray-400 inline-flex items-center leading-none text-sm">
                                  <svg
                                    className="w-4 h-4 mr-1"
                                    stroke="currentColor"
                                    strokeWidth="2"
                                    fill="none"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    viewBox="0 0 24 24"
                                  >
                                    <path d="M21 11.5a8.38 8.38 0 01-.9 3.8 8.5 8.5 0 01-7.6 4.7 8.38 8.38 0 01-3.8-.9L3 21l1.9-5.7a8.38 8.38 0 01-.9-3.8 8.5 8.5 0 014.7-7.6 8.38 8.38 0 013.8-.9h.5a8.48 8.48 0 018 8v.5z"></path>
                                  </svg>
                                  {/* {article.comments.length} */}
                                </span>
                              </div>
                            </div>
                          </div>
                        </div>
                      ))}
                    </div>
                    {!showAllArticles && (
                      <button
                        className="mt-8 px-6 py-2 text-white bg-pink-500 rounded-lg"
                        onClick={handleShowAllArticles}
                      >
                        See All Articles
                      </button>
                    )}
                  </div>
                </section>
                {showAllArticles && <AllArticles onClose={handleCloseAllArticles} />}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};


export default BlogPage;
