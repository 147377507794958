import { siteConfig } from "@/config/site";


import React from "react";


import { MainNav2 } from "@/components/structure/main-nav2";
import "./site-header-home.module.css";
import { useLocation } from "react-router-dom";
import DarkModeToggle from "@/components/ui/dark-mode-toggle/dark-mode-toggle";

export const SiteHeaderHome: React.FC = () => {
  const location = useLocation();

  console.log(location.pathname);

  return (
    <header className="pointer-events-none relative z-50 flex flex-none flex-col mt-6">
      <div
        className="absolute inset-x-0 -top-40 -z-10 transform-gpu overflow-hidden blur-3xl sm:-top-80"
        aria-hidden="true"
      >
        <div
          className="relative left-[calc(50%-11rem)] aspect-[1155/678] w-[36.125rem] -translate-x-1/2 rotate-[30deg] bg-gradient-to-tr from-[#DB2776] to-[#E6E6FA] opacity-30 sm:left-[calc(50%-30rem)] sm:w-[72.1875rem]"
          style={{
            clipPath:
              "polygon(74.1% 44.1%, 100% 61.6%, 97.5% 26.9%, 85.5% 0.1%, 80.7% 2%, 72.5% 32.5%, 60.2% 62.4%, 52.4% 68.1%, 47.5% 58.3%, 45.2% 34.5%, 27.5% 76.7%, 0.1% 64.9%, 17.9% 100%, 27.6% 76.8%, 76.1% 97.7%, 74.1% 44.1%)",
          }}
        />
      </div>
      <div className="order-last mt-[calc(theme(spacing.16)-theme(spacing.3))]"></div>
      <div className="sm:px-8 top-0 order-last -mb-3 pt-3">
        <div className="mx-auto w-full max-w-7xl lg:px-8">
          <div className="relative px-4 sm:px-8 lg:px-12">
            <div className="mx-auto max-w-2xl lg:max-w-5xl">
              <div className="top-[var(--avatar-top,theme(spacing.3))] w-full">
                <div className="relative">
                  <a className="block h-16 w-16 origin-left pointer-events-auto" href="/">
                    <img
                      src={siteConfig.ogImage}
                      className="rounded-full bg-zinc-100 object-cover dark:bg-zinc-800 h-16 w-16"
                      alt=""
                    />
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="relative flex gap-4">
        <div className="flex flex-1 justify-center items-center relative">
          <MainNav2 />
          <DarkModeToggle />
        </div>
      </div>
    </header>
  );
};
