import { Link, useLocation } from "react-router-dom";
import { cn } from "@/lib/utils";
import { nav } from '@/components/structure/navigation'

export function SiteFooter() {
  const location = useLocation();
  const currentYear = new Date();
  return (
    <footer className="mt-32 flex-none">
      
      <div className="sm:px-8">
        <div className="mx-auto w-full max-w-7xl lg:px-8">
          <div className="border-t border-zinc-100 pb-16 pt-10 dark:border-zinc-700/40">
            <div className="relative px-4 sm:px-8 lg:px-12">
              <div className="mx-auto max-w-2xl lg:max-w-5xl">
                <div className="flex flex-col items-center justify-between gap-6 sm:flex-row">
                  <div className="flex flex-wrap justify-center gap-x-6 gap-y-1 text-sm font-medium text-zinc-800 dark:text-zinc-200">
                  {nav.map((link, i) => {
            if (link.isMenu && !link.isPrivate) {
              return (
                <Link
                  key={i}
                  to={link.path}
                  className={cn(
                    "transition hover:text-pink-500 dark:hover:text-pink-400 dark:text-zinc-500",
                    location.pathname.startsWith(link.path)
                      ? "text-gray-900 font-bold"
                      : "text-gray-600"
                  )}
                >
                  {link.name}
                </Link>
              );
            }
            return null;
          })}
                  </div>
                  <p className="text-sm text-zinc-400 dark:text-zinc-500">
                    @{currentYear.getFullYear()} Dev&Beyond LLC All rights reserved.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
}


