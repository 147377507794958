
const Images = [
  {
    id: 1,
    name: "law pens",
    src: "/profile_pic4.jpeg",
    alt: "A descriptive text for screen readers and when the image can't be displayed.",
  },
  {
    id: 2,
    name: "gavel pic",
    src: "/profile_pic6.jpeg",
    alt: "Another descriptive text for better accessibility.",
  },
  {
    id: 3,
    name: "balance of scales",
    src: "/profile_pic3.jpeg",
    alt: "Image description to provide context.",
  },
  {
    id: 4,
    name: "Happy Pets",
    src: "/profile_pic7.jpeg",
    alt: "Description of the image content.",
  },
  {
    id: 5,
    name: "Happy Pets",
    src: "/law_pic3.jpg",
    alt: "Description of the image content.",
  },
];

export const RowOfCardImages = () => {
  return (
    <>
      {Images.map((image, index) => (
        <div
          key={image.id}
          className={`relative aspect-[9/10] w-44 flex-none overflow-hidden rounded-xl bg-zinc-100 dark:bg-zinc-800 sm:w-72 sm:rounded-3xl ${index % 2 === 0 ? "rotate-2" : "-rotate-2"}`}
        >
          <img
            alt={image.alt}
            loading="lazy"
            decoding="async"
            data-nimg="1"
            className="absolute inset-0 h-full w-full object-cover rounded-2xl"
            sizes="(min-width: 640px) 18rem, 11rem"
            src={image.src}
          />
        </div>
      ))}
    </>
  );
};









