import { About } from '@/components/about-components/about'
import { Portfolio } from '@/components/portfolio-components/portfolio'
import  Blogs  from '@/components/blogs'
import { Home2 } from '../home-components/home-2/home-2'


export const nav = [
    {path: "/", name:"Home", element: <Home2/>, isMenu:true, isPrivate: false },
    {path: "/about", name:"About", element: <About/>, isMenu:true, isPrivate: false },
    {path: "/portfolio", name:"Portfolio", element: <Portfolio/>, isMenu:true, isPrivate: false },
    {path: "/blogs", name:"Blogs", element: <Blogs/>, isMenu:true, isPrivate: false }
]
  