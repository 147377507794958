import React from "react";
import './about.module.css'

const TwitterIcon = () => (
  <svg
    viewBox="0 0 24 24"
    aria-hidden="true"
    className="h-6 w-6 flex-none fill-zinc-500 transition group-hover:fill-pink-500"
  >
    <path d="M20.055 7.983c.011.174.011.347.011.523 0 5.338-3.92 11.494-11.09 11.494v-.003A10.755 10.755 0 0 1 3 18.186c.308.038.618.057.928.058a7.655 7.655 0 0 0 4.841-1.733c-1.668-.032-3.13-1.16-3.642-2.805a3.753 3.753 0 0 0 1.76-.07C5.07 13.256 3.76 11.6 3.76 9.676v-.05a3.77 3.77 0 0 0 1.77.505C3.816 8.945 3.288 6.583 4.322 4.737c1.98 2.524 4.9 4.058 8.034 4.22a4.137 4.137 0 0 1 1.128-3.86A3.807 3.807 0 0 1 19 5.274a7.657 7.657 0 0 0 2.475-.98c-.29.934-.9 1.729-1.713 2.233A7.54 7.54 0 0 0 22 5.89a8.084 8.084 0 0 1-1.945 2.093Z"></path>
  </svg>
);

const InstagramIcon = () => (
  <svg
    viewBox="0 0 24 24"
    aria-hidden="true"
    className="h-6 w-6 flex-none fill-zinc-500 transition group-hover:fill-pink-500"
  >
    <path d="M12 3c-2.444 0-2.75.01-3.71.054-.959.044-1.613.196-2.185.418A4.412 4.412 0 0 0 4.51 4.511c-.5.5-.809 1.002-1.039 1.594-.222.572-.374 1.226-.418 2.184C3.01 9.25 3 9.556 3 12s.01 2.75.054 3.71c.044.959.196 1.613.418 2.185.23.592.538 1.094 1.039 1.595.5.5 1.002.808 1.594 1.038.572.222 1.226.374 2.184.418C9.25 20.99 9.556 21 12 21s2.75-.01 3.71-.054c.959-.044 1.613-.196 2.185-.419a4.412 4.412 0 0 0 1.595-1.038c.5-.5.808-1.002 1.038-1.594.222-.572.374-1.226.418-2.184.044-.96.054-1.267.054-3.711s-.01-2.75-.054-3.71c-.044-.959-.196-1.613-.419-2.185A4.412 4.412 0 0 0 19.49 4.51c-.5-.5-1.002-.809-1.594-1.039-.572-.222-1.226-.374-2.184-.418C14.75 3.01 14.444 3 12 3Zm0 1.622c2.403 0 2.688.009 3.637.052.877.04 1.354.187 1.67.31.421.163.72.358 1.036.673.315.315.51.615.673 1.035.123.317.27.794.31 1.671.043.95.052 1.234.052 3.637s-.009 2.688-.052 3.637c-.04.877-.187 1.354-.31 1.67-.163.421-.358.72-.673 1.036a2.79 2.79 0 0 1-1.035.673c-.317.123-.794.27-1.671.31-.95.043-1.234.052-3.637.052s-2.688-.009-3.637-.052c-.877-.04-1.354-.187-1.67-.31a2.789 2.789 0 0 1-1.036-.673 2.79 2.79 0 0 1-.673-1.035c-.123-.317-.27-.794-.31-1.671-.043-.95-.052-1.234-.052-3.637s.009-2.688.052-3.637c.04-.877.187-1.354.31-1.67.163-.421.358-.72.673-1.036.315-.315.615-.51 1.035-.673.317-.123.794-.27 1.671-.31.95-.043 1.234-.052 3.637-.052Z"></path>
    <path d="M12 15a3 3 0 1 1 0-6 3 3 0 0 1 0 6Zm0-7.622a4.622 4.622 0 1 0 0 9.244 4.622 4.622 0 0 0 0-9.244Zm5.884-.182a1.08 1.08 0 1 1-2.16 0 1.08 1.08 0 0 1 2.16 0Z"></path>
  </svg>
);

const LinkedInIcon = () => (
  <svg
    viewBox="0 0 24 24"
    aria-hidden="true"
    className="h-6 w-6 flex-none fill-zinc-500 transition group-hover:fill-pink-500"
  >
    <path d="M18.335 18.339H15.67v-4.177c0-.996-.02-2.278-1.39-2.278-1.389 0-1.601 1.084-1.601 2.205v4.25h-2.666V9.75h2.56v1.17h.035c.358-.674 1.228-1.387 2.528-1.387 2.7 0 3.2 1.778 3.2 4.091v4.715zM7.003 8.575a1.546 1.546 0 01-1.548-1.549 1.548 1.548 0 111.547 1.549zm1.336 9.764H5.666V9.75H8.34v8.589zM19.67 3H4.329C3.593 3 3 3.58 3 4.297v15.406C3 20.42 3.594 21 4.328 21h15.338C20.4 21 21 20.42 21 19.703V4.297C21 3.58 20.4 3 19.666 3h.003z"></path>
  </svg>
);

const EmailIcon = () => (
  <svg
    viewBox="0 0 24 24"
    aria-hidden="true"
    className="h-6 w-6 flex-none fill-zinc-500 transition group-hover:fill-pink-500"
  >
    <path
      fillRule="evenodd"
      d="M6 5a3 3 0 0 0-3 3v8a3 3 0 0 0 3 3h12a3 3 0 0 0 3-3V8a3 3 0 0 0-3-3H6Zm.245 2.187a.75.75 0 0 0-.99 1.126l6.25 5.5a.75.75 0 0 0 .99 0l6.25-5.5a.75.75 0 0 0-.99-1.126L12 12.251 6.245 7.187Z"
    ></path>
  </svg>
);

const SocialLinks = [
  {
    id: 1,
    name: "Twitter",
    href: "https://twitter.com",
    icon: <TwitterIcon />,
    classes: "flex",
    content: "Follow me on Twitter"
  },
  {
    id: 2,
    name: "Instagram",
    href: "https://instagram.com",
    icon: <InstagramIcon />,
    classes: "mt-4 flex",
    content: "Follow me on Instagram"
  },
  {
    id: 3,
    name: "LinkedIn",
    href: "https://www.linkedin.com/in/gabrielle-rancifer/",
    icon: <LinkedInIcon />,
    classes: "mt-4 flex",
    content: "Follow me on LinkedIn"
  },
  {
    id: 4,
    name: "MyEmail",
    href: "mailto:gcrancifer@gmail.com",
    icon: <EmailIcon />,
    classes: "mt-8 border-t border-zinc-100 pt-8 dark:border-zinc-700/40 flex",
    content: "gcrancifer@gmail.com"
  },
];

export const About = () => {
  return (
    <div className="sm:px-8 mt-16 sm:mt-32">
      <div className="mx-auto w-full max-w-7xl lg:px-8">
        <div className="relative px-4 sm:px-8 lg:px-12">
          <div className="mx-auto max-w-2xl lg:max-w-5xl">
            <div className="grid grid-cols-1 gap-y-16 lg:grid-cols-2 lg:grid-rows-[auto_1fr] lg:gap-y-12">
              <div className="lg:pl-20">
                <div className="max-w-xs px-2.5 lg:max-w-none">
                  <img className="aspect-square rotate-3 rounded-2xl bg-zinc-100 object-cover dark:bg-zinc-800" src="/profile_pic5.jpeg" alt="Gabrielle Rancifer" />
                </div>
              </div>
              <div className="lg:order-first lg:row-span-2">
                <h1 className="specific-font text-4xl font-extrabold tracking-tight text-zinc-800 dark:text-zinc-100 sm:text-5xl">
                  I'm Gabrielle, bridging communication and law to shape tomorrow's intellectual landscapes
                </h1>
                <div className="mt-6 space-y-7 text-base text-zinc-600 dark:text-zinc-400">
                  <p>
                    At Northwestern’s prestigious Medill School of Journalism, I
                    proudly completed the Accelerated Master's Program (AMP). My
                    tenure at Medill was marked by intense immersion into the
                    multifaceted world of communication. I specialized in
                    Health, Environment & Science, embracing both traditional
                    narrative writing techniques and modern interactive
                    storytelling paradigms.
                  </p>
                  <p>
                    My extensive background, brimming with professional
                    experiences in both journalism and public relations, has
                    granted me invaluable insights and skills. These experiences
                    have been instrumental in shaping my unique perspective and
                    approach to communication.
                  </p>
                  <p>
                    The fall of 2023 marked a pivotal moment in my academic
                    journey as I embarked on a new path—law studies. My decision
                    to delve into the legal domain was not a mere divergence
                    from journalism but rather a deepened extension of my
                    passion for communication.
                  </p>
                  <p>
                    Intellectual Property law, with its intricate nuances and
                    vast significance in today's digital age, captivated my
                    interest. As I navigate through the complexities of this
                    legal field, my ambition is not just to become proficient
                    but to pioneer the amalgamation of my expertise in
                    communication with nuanced legal analysis. Environmental
                    law, in particular, holds a special place in my pursuits,
                    echoing my earlier specializations and driving me to make
                    impactful strides in this essential arena
                  </p>
                </div>
              </div>
              <div className="lg:pl-20">
                <ul>
                  {SocialLinks.map((sl) => (
                    <li key={sl.id} className={sl.classes}>
                      <a
                        className="group flex text-sm font-semibold text-zinc-800 transition hover:text-pink-500 dark:text-zinc-200 dark:hover:text-pink-500"
                        href={sl.href} // Assuming you have a link property in your SocialLinks objects.
                      >
                        <svg
                          viewBox="0 0 24 24"
                          aria-hidden="true"
                          className="h-6 w-6 flex-none fill-zinc-500 transition group-hover:fill-pink-500"
                        >
                          {sl.icon}
                        </svg>
                        <span className="ml-4">
                          {sl.content}
                        </span>
                      </a>
                    </li>
                  ))}
                </ul>
              </div>
              
            </div>
          </div>
        </div>
      </div>
      
    </div>
  );
};



