import React, { createContext, useState, ReactNode } from 'react';

export const ThemeContext = createContext({
    theme: 'light',
    toggleTheme: (themeName: string) => {},
});

interface ThemeProviderProps {
    children: ReactNode;
}

export const ThemeProvider = ({ children }: ThemeProviderProps) => {
    const [theme, setTheme] = useState('light');

    // this function could be used to toggle the theme
    const toggleTheme = (themeName: string) => {
        setTheme(themeName);
    };

    return (
        <ThemeContext.Provider value={{ theme, toggleTheme }}>
            {children}
        </ThemeContext.Provider>
    );
};