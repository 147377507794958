import React from "react";
import {
  Card,
  CardContent,
  CardFooter,
  CardHeader,
  CardTitle,
} from "@/components/ui/card";
import { Button } from "@/components/ui/button";
import styles from "./resume-section.module.css";
import { Link } from "react-router-dom";

const jobs = [
  {
    id: 1,
    companyName: "Milwaukee Journal Sentinel",
    position: "Media Law & Govt. Transparency Practicum",
    startDate: new Date("2023-01-01"),
    endDate: undefined,
    companyLogo: "/Milwaukee_Journal_Sentinel_logo.jpg", // Fill in with the company logo if available
  },
  {
    id: 2,
    companyName: "Rise Strategy Group",
    position: "Public Strategy Intern",
    startDate: new Date("2022-08-01"),
    endDate: new Date("2022-12-31"),
    companyLogo: "/rise_strategy_group_logo.webp", // Fill in with the company logo if available
  },
  {
    id: 3,
    companyName: "Medill School of Journalism",
    position: "Marketing Assistant - Work Study",
    startDate: new Date("2022-01-01"),
    endDate: new Date("2023-01-31"),
    companyLogo: "/Medill_School_of_Journalism_logo.jpg", // Fill in with the school's logo if available
  },
  {
    id: 4,
    companyName: "Kivvit",
    position: "Public Af airs Trainee/Assistant",
    startDate: new Date("2021-03-01"),
    endDate: new Date("2022-06-30"),
    companyLogo: "/Kivvit_logo.jpg", // Fill in with the company logo if available
  },
  {
    id: 5,
    companyName: "Modesto Management",
    position: "Writing & Communications Consultant",
    startDate: new Date("2021-12-01"),
    endDate: new Date("2022-07-31"),
    companyLogo: "/Modesto_Management_logo.webp", // Fill in with the company logo if available
  },
];

export const ResumeSection: React.FC = () => {
  const resumeLink = "/resume.pdf";

  const downloadResume = () => {
    const link = document.createElement("a");
    link.href = resumeLink;
    link.download = "resume.pdf"; // optional, if you want to set a filename
    link.click();
  };

  return (
    <Card className="space-y-0 rounded-2xl border border-zinc-100 p-0 dark:border-zinc-700/40">
      <CardHeader>
        <CardTitle className="flex text-sm font-semibold text-zinc-900 dark:text-zinc-100">
          <svg
            viewBox="0 0 24 24"
            fill="none"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
            aria-hidden="true"
            className="h-6 w-6 flex-none"
          >
            <path
              d="M2.75 9.75a3 3 0 0 1 3-3h12.5a3 3 0 0 1 3 3v8.5a3 3 0 0 1-3 3H5.75a3 3 0 0 1-3-3v-8.5Z"
              className="fill-zinc-100 stroke-zinc-400 dark:fill-zinc-100/10 dark:stroke-zinc-500"
            ></path>
            <path
              d="M3 14.25h6.249c.484 0 .952-.002 1.316.319l.777.682a.996.996 0 0 0 1.316 0l.777-.682c.364-.32.832-.319 1.316-.319H21M8.75 6.5V4.75a2 2 0 0 1 2-2h2.5a2 2 0 0 1 2 2V6.5"
              className="stroke-zinc-400 dark:stroke-zinc-500"
            ></path>
          </svg>
          <span className="ml-3 mt-1">Work</span>
        </CardTitle>
      </CardHeader>
      <CardContent>
        <div className="flex flex-col gap-4">
          {jobs.map((job) => (
            <ol key={job.id} className="mt-6 space-y-4">
              <li className="flex gap-4">
                <div>
                  <img
                    alt=""
                    loading="lazy"
                    width="32"
                    height="32"
                    className="h-7 w-7"
                    src={job.companyLogo}
                  />
                </div>
                <dl className="flex flex-auto flex-wrap gap-x-2">
                  <dd className="w-full flex-none text-sm font-medium text-zinc-900 dark:text-zinc-100">
                    {job.companyName}
                  </dd>
                  <dd
                    className={`${styles.forceWrap} text-xs text-zinc-500 dark:text-zinc-400`}
                  >
                    {job.position}
                  </dd>
                  <dd className="ml-auto text-xs text-zinc-400 dark:text-zinc-500">
                    <time dateTime={job.startDate.toISOString()}>
                      {job.startDate.getFullYear()}
                    </time>
                    <span aria-hidden="true">—</span>
                    {job.endDate ? (
                      <time dateTime={job.endDate.toISOString()}>
                        {job.endDate.getFullYear()}
                      </time>
                    ) : (
                      <span>Present</span>
                    )}
                  </dd>
                </dl>
              </li>
            </ol>
          ))}
        </div>
      </CardContent>
      <CardFooter>
        <Button
          asChild
          variant="outline"
          className="bg-gray-100 w-full border-0 dark:text-zinc-600"
        >
          <Link to="/" onClick={downloadResume}>
            Download CV
            <svg
              viewBox="0 0 16 16"
              fill="none"
              aria-hidden="true"
              className="h-4 w-4 stroke-zinc-400 transition group-active:stroke-zinc-600 dark:group-hover:stroke-zinc-50 dark:group-active:stroke-zinc-50 dark:text-zinc-400"
            >
              <path
                d="M4.75 8.75 8 12.25m0 0 3.25-3.5M8 12.25v-8.5"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
              ></path>
            </svg>
          </Link>
        </Button>
      </CardFooter>
    </Card>
  );
};
