//App.tsx

import { BrowserRouter as Router } from "react-router-dom";
import { ThemeProvider } from './components/theme-context';
import { MainApp } from '@/components/main-app-components/main-app'
import { BlogProvider } from '@/data/blog-context'
import { myBlog } from '@/data/blogData';


function App() {
  
  return (
   <Router>
    <ThemeProvider>
    <BlogProvider initialBlog={myBlog}>
      <div className="flex h-full bg-zinc-50 dark:bg-black">
        <MainApp/>
      </div>
    </BlogProvider>
    </ThemeProvider>
   </Router>
  );
}

export default App;
