import React from "react";
import "./portfolio.module.css";

import {
  File,
  HelpCircle,
  Image,
  Laptop,
  Wallpaper,
  Users,
  Newspaper,
  Album,
} from "lucide-react";

const Projects = [
  {
    id: 1,
    projectName: "When the Science is Done",
    description: "",
    projectLink: "https://gcrancifer.my.canva.site/when-the-science-is-done",
    icon: Laptop,
    isActive: true,
    category: "Academic Achievements",
  },
  {
    id: 2,
    projectName: "Tidepooling: Nature is our greatest teacher",
    description: "",
    projectLink: "https://gcrancifer.my.canva.site/tidepooling",
    icon: Users,
    isActive: true,
    category: "Extracurricular Activities",
  },
  {
    id: 3,
    projectName: "Industrialized Nature S1 E2: POPs & PFAs & Plastics, Oh my!",
    description: "",
    projectLink:
      "https://soundcloud.com/gabi-ranci/industrialized-nature-s1-e2?si=436babf39d6c47fd91d4bfffd6377b4c&utm_source=clipboard&utm_medium=text&utm_campaign=social_sharing",
    icon: Newspaper,
    isActive: true,
    category: "Legal Journals",
  },
  {
    id: 4,
    projectName: "Legal Career Fair Organizer",
    description:
      "Co-organized a legal career fair, facilitating interactions between students and leading legal firms.",
    projectLink: "#",
    icon: File,
    isActive: false,
    category: "Networking Events & Conferences",
  },
  {
    id: 5,
    projectName: "Community Legal Clinic Volunteer",
    description:
      "Assisted in offering legal advice on housing and employment issues to underprivileged communities.",
    projectLink: "#",
    icon: Album,
    isActive: false,
    category: "Pro Bono Work",
  },
  {
    id: 6,
    projectName: "Criminal Law Research Assistant",
    description:
      "Assisted Professor Smith in researching case laws and statutes related to white-collar crimes.",
    projectLink: "#",
    icon: Wallpaper,
    isActive: false,
    category: "Academic Achievements",
  },
  {
    id: 7,
    projectName: "Legal Blogging on Constitutional Rights",
    description:
      "Authored four articles focusing on the evolution of First Amendment rights in the digital age.",
    projectLink: "#",
    icon: HelpCircle,
    isActive: false,
    category: "Extracurricular Activities",
  },
  {
    id: 8,
    projectName: "Legal Networking Seminar",
    description:
      "Participated in a seminar to understand the importance of networking and its role in legal careers.",
    projectLink: "#",
    icon: Image,
    isActive: false,
    category: "Networking Events & Conferences",
  },
];

export const Portfolio = () => {
  return (
    <>
      <div className="sm:px-8 mt-16 sm:mt-32">
        <div className="mx-auto w-full max-w-7xl lg:px-8">
          <div className="relative px-4 sm:px-8 lg:px-12">
            <div className="mx-auto max-w-2xl lg:max-w-5xl">
              <header className="max-w-2xl">
                <h1 className="text-4xl font-bold tracking-tight text-zinc-800 dark:text-zinc-100 sm:text-5xl">
                  Embarking on my legal odyssey: First Year Highlights
                </h1>
                <p className="mt-6 text-base text-zinc-600 dark:text-zinc-400">
                  The past year has seen me dive deep into the rich tapestry of
                  law, engaging in numerous endeavors that have shaped my
                  budding legal perspective. While it's just the beginning,
                  these experiences stand out as milestones in my journey. Some
                  of the cases and projects I've explored are open for deeper
                  discussion. If something catches your eye, let's engage in a
                  dialogue or explore the intricacies together.
                </p>
              </header>
              <div className="mt-16 sm:mt-20">
                <ul className="grid grid-cols-1 gap-x-12 gap-y-16 sm:grid-cols-2 lg:grid-cols-3">
                  {Projects.filter((project) => project.isActive).map(
                    (project) => {
                      const IconComponent = project.icon;
                      return (
                        // Destructure the icon from the project
                        <li
                          key={project.id}
                          className="group relative flex flex-col items-start"
                        >
                          <div className="relative z-10 flex h-12 w-12 items-center justify-center rounded-full bg-white shadow-md shadow-zinc-800/5 ring-1 ring-zinc-900/5 dark:border dark:border-zinc-700/50 dark:bg-zinc-800 dark:ring-0">
                            <IconComponent color="#db2776" />{" "}
                            {/* Render the icon component */}
                          </div>
                          <h2 className="mt-6 text-base font-semibold text-zinc-800 dark:text-zinc-100">
                            <div className="absolute -inset-x-4 -inset-y-6 z-0 scale-95 bg-zinc-50 opacity-0 transition group-hover:scale-100 group-hover:opacity-100 dark:bg-zinc-800/50 sm:-inset-x-6 sm:rounded-2xl"></div>
                            <a href={project.projectLink}>
                              <span className="absolute -inset-x-4 -inset-y-6 z-20 sm:-inset-x-6 sm:rounded-2xl"></span>
                              <span className="relative z-10">
                                {project.projectName}
                              </span>
                            </a>
                          </h2>
                          <p className="relative z-10 mt-2 text-sm text-zinc-600 dark:text-zinc-400">
                            {project.description}
                          </p>
                          <p className="relative z-10 mt-6 flex text-sm font-medium text-zinc-400 transition group-hover:text-teal-500 dark:text-zinc-200">
                            <svg
                              viewBox="0 0 24 24"
                              aria-hidden="true"
                              className="h-6 w-6 flex-none"
                            >
                              <path
                                d="M15.712 11.823a.75.75 0 1 0 1.06 1.06l-1.06-1.06Zm-4.95 1.768a.75.75 0 0 0 1.06-1.06l-1.06 1.06Zm-2.475-1.414a.75.75 0 1 0-1.06-1.06l1.06 1.06Zm4.95-1.768a.75.75 0 1 0-1.06 1.06l1.06-1.06Zm3.359.53-.884.884 1.06 1.06.885-.883-1.061-1.06Zm-4.95-2.12 1.414-1.415L12 6.344l-1.415 1.413 1.061 1.061Zm0 3.535a2.5 2.5 0 0 1 0-3.536l-1.06-1.06a4 4 0 0 0 0 5.656l1.06-1.06Zm4.95-4.95a2.5 2.5 0 0 1 0 3.535L17.656 12a4 4 0 0 0 0-5.657l-1.06 1.06Zm1.06-1.06a4 4 0 0 0-5.656 0l1.06 1.06a2.5 2.5 0 0 1 3.536 0l1.06-1.06Zm-7.07 7.07.176.177 1.06-1.06-.176-.177-1.06 1.06Zm-3.183-.353.884-.884-1.06-1.06-.884.883 1.06 1.06Zm4.95 2.121-1.414 1.414 1.06 1.06 1.415-1.413-1.06-1.061Zm0-3.536a2.5 2.5 0 0 1 0 3.536l1.06 1.06a4 4 0 0 0 0-5.656l-1.06 1.06Zm-4.95 4.95a2.5 2.5 0 0 1 0-3.535L6.344 12a4 4 0 0 0 0 5.656l1.06-1.06Zm-1.06 1.06a4 4 0 0 0 5.657 0l-1.061-1.06a2.5 2.5 0 0 1-3.535 0l-1.061 1.06Zm7.07-7.07-.176-.177-1.06 1.06.176.178 1.06-1.061Z"
                                fill="currentColor"
                              ></path>
                            </svg>
                            <span className="ml-2 truncate">
                              <a
                                href={project.projectLink}
                                target="_blank"
                                rel="noopener noreferrer"
                              >
                                Check it out!
                              </a>
                            </span>
                          </p>
                        </li>
                      );
                    }
                  )}
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
