import React from "react";
import { useLocation } from "react-router-dom";
import { SiteHeader } from "../structure/site-header";
import { SiteFooter } from "@/components/structure/site-footer";
import { RenderRoutes } from "@/components/structure/render-navigation";
import { SiteHeaderHome } from "../structure/home-site-header/site-header-home";

export const MainApp = () => {
  const location = useLocation();
  const renderHeader = () => {
    if (location.pathname === '/') {
      return <SiteHeaderHome />;
    }
    return <SiteHeader />;
  }

  return (
    
      <div className="flex w-full">
      <div className="fixed inset-0 flex justify-center sm:px-8">
        <div className="flex w-full max-w-7xl lg:px-8">
          <div className="w-full bg-white ring-1 ring-zinc-100 dark:bg-zinc-900 dark:ring-zinc-300/20"></div>
        </div>
      </div>
      <div className="relative flex w-full flex-col">
        {renderHeader()}
        <div className="flex-none content-offset"></div>
        <main className="flex-auto">
          <RenderRoutes />
        </main>
        <SiteFooter />
      </div>
    </div>
     
    
    
  );
};

