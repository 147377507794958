import React, { createContext, useContext, useState, useMemo } from 'react';
import { Blog } from '@/models/blog'

type BlogContextType = {
    blog: Blog;
    setBlog: React.Dispatch<React.SetStateAction<Blog>>;
}

type BlogProviderProps = {
    initialBlog: Blog;
    children: React.ReactNode;
}

const BlogContext = createContext<BlogContextType | undefined>(undefined);

export const BlogProvider: React.FC<BlogProviderProps> = (props) => {
    const [blog, setBlog] = useState<Blog>(props.initialBlog);

    // Using useMemo to avoid unnecessary re-renders
    const contextValue = useMemo(() => ({ blog, setBlog }), [blog]);

    return (
        <BlogContext.Provider value={contextValue}>
            {props.children}
        </BlogContext.Provider>
    );
}

export const useBlog = () => {
    const context = useContext(BlogContext);
    if (!context) {
      throw new Error('useBlog must be used within a BlogProvider');
    }
    return context;
};


