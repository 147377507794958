import { siteConfig } from "@/config/site";
import { MainNav2 } from "./main-nav2";
import "./site-header.module.css";
import DarkModeToggle from "../ui/dark-mode-toggle/dark-mode-toggle";

export function SiteHeader() {

  return (
    <>
      <header className="pointer-events-none relative z-50 flex flex-none flex-col custom-header-style">
        <div
          className="absolute inset-x-0 -top-40 -z-10 transform-gpu overflow-hidden blur-3xl sm:-top-80"
          aria-hidden="true"
        >
          <div
            className="relative left-[calc(50%-11rem)] aspect-[1155/678] w-[36.125rem] -translate-x-1/2 rotate-[30deg] bg-gradient-to-tr from-[#DB2776] to-[#E6E6FA] opacity-30 sm:left-[calc(50%-30rem)] sm:w-[72.1875rem]"
            style={{
              clipPath:
                "polygon(74.1% 44.1%, 100% 61.6%, 97.5% 26.9%, 85.5% 0.1%, 80.7% 2%, 72.5% 32.5%, 60.2% 62.4%, 52.4% 68.1%, 47.5% 58.3%, 45.2% 34.5%, 27.5% 76.7%, 0.1% 64.9%, 17.9% 100%, 27.6% 76.8%, 76.1% 97.7%, 74.1% 44.1%)",
            }}
          />
        </div>
          <div className="top-0 z-10 h-16 pt-6 header-position">
            <div className="sm:px-8 header-top w-full header-inner-position">
              <div className="mx-auto w-full max-w-7xl lg:px-8">
                <div className="relative px-4 sm:px-8 lg:px-12">
                  <div className="mx-auto max-w-2xl lg:max-w-5xl">
                    <div className="relative flex gap-4">
                      <div className="flex flex-1">
                        <div className="h-10 w-10 rounded-full bg-white/90 p-0.5 shadow-lg shadow-zinc-800/5 ring-1 ring-zinc-900/5 backdrop-blur dark:bg-zinc-800/90 dark:ring-white/10">
                          <a
                            aria-label="Home"
                            href="/"
                            className="pointer-events-auto"
                          >
                            <img
                              alt=""
                              className="rounded-full bg-zinc-100 object-cover dark:bg-zinc-800 h-9 w-9 image-size avatar-image-color"
                              src={siteConfig.ogImage}
                            />
                          </a>
                        </div>
                      </div>
                      
                        <div className="visually-hidden"></div>
                        <MainNav2 />
                      </div>
                      <div className="flex justify-end md:flex-1">
                        
                        <DarkModeToggle/>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          
        
      </header>
      <div className="flex-none customHeight"></div>
    </>
  );
}


