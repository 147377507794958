//render-navigation.tsx
import { Route, Routes } from "react-router-dom";
import { nav } from "@/components/structure/navigation";


export const RenderRoutes = () => {
    return (
      <Routes>
        {nav.map((r, i) => (
          <Route key={i} path={r.path} element={r.element} />
        ))}
      </Routes>
    );
  };